.app {
  font-family: 'Roboto', sans-serif;
  background-color: #aec2cd;
}

.header {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: calc(10px + 2vmin);
  color: #040037;
}

.header h1 {
  margin-bottom: 0px;
  font-size: 2.4em;
}

.header h4 {
  width: 90%;
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  font-weight: normal;
}

.header h4 span {
  padding: 0 24px;
}

.header h4:before, .header h4:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #000;
  margin: auto;
}

.header #skills {
  padding: 16px 0 52px 0;
}

.header #skills div {
  font-size: 12px;
  padding: 4px;
}